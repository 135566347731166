import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useSettingsSelector } from './settings/use-settings-selector'

export const useProject = () => {
	const wId = useSettingsSelector((settings) => settings.workspaceId)
	const pId = useSettingsSelector((settings) => settings.projectId)
	const isSingle = useSettingsSelector((settings) => settings.single, false)
	const params = useParams<{ workspaceId: string; projectId: string }>()
	const location = useLocation()

	const projectData = useMemo(() => {
		let workspaceId, projectId

		if (isSingle) {
			workspaceId = params.workspaceId ? Number(params.workspaceId) : wId
			projectId = params.projectId ? Number(params.projectId) : pId
		} else {
			const searchParams = new URLSearchParams(location.search)
			workspaceId = searchParams.get('workspaceId')
				? Number(searchParams.get('workspaceId'))
				: wId
			projectId = searchParams.get('projectId')
				? Number(searchParams.get('projectId'))
				: pId
		}

		return {
			projectId,
			workspaceId,
		}
	}, [isSingle, params, location.search, wId, pId])

	return projectData
}
